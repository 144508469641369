import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const config = {
  apiKey: "AIzaSyBifco9TlEMEJ5klWSzGtD3PApXzPs-XE0",
  authDomain: "lecxitmath.firebaseapp.com",
  databaseURL: "https://lecxitmath-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "lecxitmath",
  storageBucket: "lecxitmath.appspot.com",
  messagingSenderId: "727862876501",
  appId: "1:727862876501:web:5e4eb18caf71a6d4ee77dc",
  measurementId: "G-44VYFEFZBP"
};


export const app = initializeApp(config);
export const firestore = getFirestore(app);
export const db = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);