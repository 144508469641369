import { useState } from 'react';
import { Form, Select, Upload, Button, message, Space, Spin, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ref, uploadBytes, getDownloadURL, updateMetadata } from 'firebase/storage';
import { storage } from "./_utilities/Firebase";

const { Option } = Select;
const { Dragger } = Upload;

const MyForm = (props) => {
  const [showUpload, setShowUpload] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [type, setType] = useState('');
  const [form] = Form.useForm();
  const [uploadedFiles, setUploadedFiles] = useState([]); // New state variable for storing the uploaded files
  const [showForm, setShowForm] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [originalFileName, setOriginalFileName] = useState('');
  const [uploadedFileURL, setUploadedFileURL] = useState('');
  let { school, selectedGroup, refreshSchoolFiles, onOk, getSchoolGroup } = props;

  const handleFileUpload = async (files) => {
    try {
      setLoadingForm(true);
      const storageRef = ref(storage);

      await Promise.all(
        files.map(async (file, index) => {
          setOriginalFileName(file.name);
          let fileName = '';
          let counter = -1;

          if (type === 'al') {
            counter = selectedGroup['attendanceList'] + index;
            fileName = `${file.name.split('.')[0]}_al_${counter + 1}.${file.name.split('.').pop()}`;
            selectedGroup['attendanceList'] += 1; // Increment the counter for attendanceList
          } else if (type === 'gl') {
            counter = selectedGroup['gradesLists'] + index;
            fileName = `${file.name.split('.')[0]}_gl_${counter + 1}.${file.name.split('.').pop()}`;
            selectedGroup['gradesLists'] += 1; // Increment the counter for gradesLists
          } else if (type === 'ot') {
            counter = selectedGroup['others'] + index;
            fileName = `${file.name.split('.')[0]}_ot_${counter + 1}.${file.name.split('.').pop()}`;
            selectedGroup['others'] += 1; // Increment the counter for others
          } else if (type === 'ag') {
            counter = selectedGroup['attendanceGradesList'] + index;
            fileName = `${file.name.split('.')[0]}_ag_${counter + 1}.${file.name.split('.').pop()}`;
            selectedGroup['attendanceGradesList'] += 1; // Increment the counter for attendanceGradesList
          } else if (type === 'qr') {
            counter = selectedGroup['qualitativeReport'] + index;
            fileName = `${file.name.split('.')[0]}_qr_${counter + 1}.${file.name.split('.').pop()}`;
            selectedGroup['qualitativeReport'] += 1; // Increment the counter for qualitativeReport
          }

          const directoryPath = `grades/${school}/${selectedGroup.group}/`;
          const fileRef = ref(storageRef, directoryPath + fileName);
          await uploadBytes(fileRef, file);

          // Set metadata with the original filename
          await updateMetadata(fileRef, {
            customMetadata: {
              originalFileName: file.name,
            },
            contentDisposition: `attachment; filename="${encodeURIComponent(file.name)}"`,
          });

          const fileURL = await getDownloadURL(fileRef);
          setUploadedFiles((prevUploadedFiles) => [
            ...prevUploadedFiles,
            { fileName: file.name, fileURL },
          ]);

          if (type === 'al') {
            selectedGroup['attendanceList'] = counter;
          } else if (type === 'gl') {
            selectedGroup['gradesLists'] = counter;
          } else if (type === 'ot') {
            selectedGroup['others'] = counter;
          } else if (type === 'ag') {
            selectedGroup['attendanceGradesList'] = counter;
          } else if (type === 'qr') {
            selectedGroup['qualitativeReport'] = counter;
          }
        })
      );

      message.success('Fitxer(s) pujat(s) correctament');
      form.resetFields(); // Reset the entire form fields after successful file upload
      await refreshSchoolFiles();
      setShowForm(false);
      setLoadingForm(false);
      //onOk();
    } catch (error) {
      console.error('Error de càrrega amb el(s) fitxer(s):', error);
      message.error('Error de càrrega');
    } finally {
      setUploading(false);
    }
  };


  const handleTypeChange = (value) => {
    setType(value);
    setShowUpload(value !== '');
    form.resetFields(['file']); // Reset the file field when the type changes
  };

  const beforeUpload = (file) => {
    // Perform any necessary checks before upload, if required
    return true;
  };

  const handleFileChange = (info) => {
    const { fileList } = info;

    if (fileList.some((file) => file.status === 'done')) {
      message.success('Fitxer(s) pujat(s) correctament');
    }

    if (fileList.some((file) => file.status === 'error')) {
      message.error('Un o més fitxers no s\'han pujat correctament');
    }

    if (fileList.every((file) => file.status === 'done')) {
      setUploading(true);
      handleFileUpload(fileList.map((file) => file.originFileObj));
    }
  };

  const newUpload = () => {
    setShowForm(true);
    setUploadedFileURL('');
    setOriginalFileName('');
    onOk();
    setShowUpload(false);
    setUploadedFiles([]);
  }

  return (
    <>
      {loadingForm ? (
        <>
          <Row align='middle' justify='center'>
            <Col>
              <Spin size="default" />
            </Col>
          </Row>
        </>
      ) : (
        <>
          {showForm && (
            <Form
              form={form}
              style={{
                maxWidth: 600,
              }}
              autoComplete="off"
            >
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Si us plau, escull el tipus de fitxer',
                  },
                ]}
              >
                <Select
                  placeholder="Tipus de fitxer"
                  onChange={handleTypeChange}
                >
                  <Option value="al">Llistat(s) d'assistència(es)</Option>
                  <Option value="gl">Les notes dels/les estudiants</Option>
                  <Option value="ag">Llistat(s) i notes dels/les estudiants</Option>
                  <Option value="qr">Informe(s) qualitatiu(s)</Option>
                  <Option value="ot">Un(s) altre(s) fitxer(s)</Option>
                </Select>
              </Form.Item>

              {showUpload && (
                <>
                  <Form.Item
                    name="file"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e.fileList}
                    rules={[
                      {
                        required: true,
                        message: 'Please select a file',
                      },
                    ]}
                  >
                    <Dragger
                      beforeUpload={beforeUpload}
                      onChange={handleFileChange} // Use handleFileChange to trigger file upload
                      customRequest={({ file }) => handleFileUpload([file])} // Handle custom request for single file upload
                      multiple // Enable multiple file upload
                      showUploadList={{ showRemoveIcon: false }} // Hide remove icons for individual files
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text">Selecciona o arrossega els fitxers per pujar-los
                      - <strong>
                        {type==='al'?"Llistat(s) d'assistència(es)":''}
                        {type==='gl'?"Les notes dels/les estudiants":''}
                        {type==='ag'?"Llistat(s) i notes dels/les estudiants":''}
                        {type==='qr'?"Informe(s) qualitatiu(s)":''}  
                        {type==='qr'?"Un(s) altre(s) fitxer(s)":''}  
                        </strong>
                      </p>
                    </Dragger>
                  </Form.Item>
                </>
              )}
            </Form>
          )}

          {uploadedFiles.length > 0 && (
            <div>
              <Space direction='vertical'>
                <p style={{ marginTop: '0', marginBottom: '0' }}>Pots fer clic aquí per veure els fitxers que has pujat:</p>
                {uploadedFiles.map((file, index) => (
                  <a key={index} href={file.fileURL} target="_blank" rel="noopener noreferrer">
                    {file.fileName}
                  </a>
                ))}
                <Button onClick={newUpload}>Pujar un altre fitxer</Button>
              </Space>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MyForm;
